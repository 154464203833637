<template>
  <div class="base-card-create-offer">
    <div class="title-card">
      {{ $t('components.card_available_for_offer.title_card') }}
    </div>
    <div v-if="hasOperationalCapacity">
      <div class="subtitle-base-card-create-offer">
        {{ $t('components.card_available_for_offer.subtitle_offer_credit') }}
      </div>
      <div class="credits-available-for-offer">
        <div class="credits-container">
          <credit-card
            v-model="offer.paper"
            class-name="paper"
            :types-material="$t('components.card_available_for_offer.materials.paper.name')"
            :amount-credits="getAvailablePaperCredit"
            :credits-pendings="getPaperCreditPending"
            :suggested-value="quickValue.paper"
            :active="isSelected(
              $t('components.card_available_for_offer.materials.paper.className')
            )"
            @click="selectCard"
          />
          <credit-card
            v-model="offer.plastic"
            class-name="plastic"
            :types-material="$t('components.card_available_for_offer.materials.plastic.name')"
            :amount-credits="getAvailablePlasticCredit"
            :credits-pendings="getPlasticCreditPending"
            :suggested-value="quickValue.plastic"
            :active="isSelected(
              $t('components.card_available_for_offer.materials.plastic.className')
            )"
            @click="selectCard"
          />

          <credit-card
            v-model="offer.glass"
            class-name="glass"
            :types-material="$t('components.card_available_for_offer.materials.glass.name')"
            :amount-credits="getAvailableGlassCredit"
            :credits-pendings="getGlassCreditPending"
            :suggested-value="quickValue.glass"
            :active="isSelected(
              $t('components.card_available_for_offer.materials.glass.className')
            )"
            @click="selectCard"
          />

          <credit-card
            v-model="offer.metal"
            class-name="metal"
            :types-material="$t('components.card_available_for_offer.materials.metal.name')"
            :amount-credits="getAvailableMetalCredit"
            :credits-pendings="getMetalCreditPending"
            :suggested-value="quickValue.metal"
            :active="isSelected(
              $t('components.card_available_for_offer.materials.metal.className')
            )"
            @click="selectCard"
          />
        </div>
      </div>
      <div class="content-button">
        <rectoplus-button-outlined
          class="generate-offer-button"
          :disabled="!cardSelected"
          :text="$t('components.card_available_for_offer.buttons.credit_offer')"
          @click="addResume"
        />
      </div>
    </div>
    <div v-else>
      <div class="content-card">
        <label class="subtitle-card">
          {{ $t('components.card_available_for_offer.subtitle_register') }}
        </label>
      </div>
      <rectoplus-button
        class="btn-complete-registration"
        :text="$t('components.card_available_for_offer.buttons.complete_registration')"
        @click="checkBusinessKind"
      />
      <business-kind-dialog
        v-model="dialog"
        @submited="goToBusinessForm"
      />
    </div>
    <generic-confirmation-dialog
      v-model="openDialog"
      component="out_of_range"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import formatMoney from '@/mixins/formatMoney';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';
import RectoplusButtonOutlined from '@/components/buttons/RectoplusButtonOutlined.vue';
import BusinessKindDialog from '@/components/forms/register/business/BusinessKindDialog.vue';
import CreditCard from '@/components/cards/credit/CreditCard.vue';
import GenericConfirmationDialog from '@/components/dialogs/GenericConfirmationDialog.vue';

export default {
  name: 'BaseCardCreateOffer',
  components: {
    RectoplusButton,
    BusinessKindDialog,
    CreditCard,
    RectoplusButtonOutlined,
    GenericConfirmationDialog,
  },
  mixins: [formatMoney],
  data() {
    return {
      dialog: false,
      cardSelected: '',
      openDialog: false,
      offer: {
        paper: {
          type: 'Papel',
          credit: null,
          valueUnit: null,
        },
        plastic: {
          type: 'Plástico',
          credit: null,
          valueUnit: null,
        },
        glass: {
          type: 'Vidro',
          credit: null,
          valueUnit: null,
        },
        metal: {
          type: 'Metal',
          credit: null,
          valueUnit: null,
        },
      },
      resume: [],
    };
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'hasOperationalCapacity',
      'getBusiness',
      'getPaperCreditPending',
      'getPlasticCreditPending',
      'getMetalCreditPending',
      'getGlassCreditPending',
      'getAvailableGlassCredit',
      'getAvailableMetalCredit',
      'getAvailablePlasticCredit',
      'getAvailablePaperCredit',
      'getQuickValues',
    ]),
    materialsSelecteds() {
      return Object.keys(this.offer)
        .filter((material) => this.validatePresenceOfferValues(material));
    },

    quickValue() {
      const values = {
        paper: this.getQuickValues[0]?.paper_value || 0,
        plastic: this.getQuickValues[0]?.plastic_value || 0,
        glass: this.getQuickValues[0]?.glass_value || 0,
        metal: this.getQuickValues[0]?.metal_value || 0,
      };

      return values;
    },
  },
  mounted() {
    this.fetchMyCredits({ business_id: this.getBusiness.id });
    this.fetchQuickValues();
  },
  methods: {
    ...mapActions([
      'fetchMyCredits',
      'addErrorMessage',
      'fetchQuickValues',
    ]),

    formatUnitValue(unitValue) {
      if (!unitValue) return 0;
      return Number(unitValue.replace(',', '.'));
    },

    getIndexOffer(material) {
      return this.resume.findIndex((offer) => offer.material === material);
    },

    valueTotal(material) {
      return (this.offer[material].credit
              * this.formatUnitValue(this.offer[material].valueUnit)).toFixed(2);
    },

    checkBusinessKind() {
      if (this.getBusiness.kind) {
        this.goToBusinessForm();
      } else {
        this.dialog = true;
      }
    },

    goToBusinessForm() {
      this.$router.push({ name: 'business' });
    },

    selectCard(selected) {
      this.cardSelected = selected;
    },

    validatePresenceOfferValues(material) {
      return this.offer[material].credit && this.formatUnitValue(this.offer[material].valueUnit);
    },

    validateInvalidValues(material) {
      return Number(this.offer[material].credit) === 0
             || this.formatUnitValue(this.offer[material].valueUnit) === 0;
    },

    addResume() {
      if (this.materialsSelecteds.length === 0) {
        this.addErrorMessage(this.$t('errors.invalid_credit_quantity'));
      } else {
        this.materialsSelecteds.forEach((material) => {
          this.setOfferOnResume(material);
        });

        this.offer = this.offerDefault();
        this.$emit('addOffer', this.resume);
      }
    },

    displayDialog(material) {
      if (this.formatUnitValue(this.offer[material].valueUnit)
        > (this.quickValue[material] + (this.quickValue[material] * 0.35))) {
        this.openDialog = true;
      }

      return this.openDialog;
    },

    setOfferOnResume(material) {
      this.resume.push({
        material,
        type: this.offer[material].type,
        quantity: Number(this.offer[material].credit),
        unit_value: this.formatUnitValue(this.offer[material].valueUnit),
        private_value: false,
        total_value: this.valueTotal(material),
      });
    },

    isSelected(selected) {
      return this.cardSelected === selected;
    },

    offerDefault() {
      return {
        paper: {
          type: 'Papel',
          credit: null,
          valueUnit: null,
        },
        plastic: {
          type: 'Plástico',
          credit: null,
          valueUnit: null,
        },
        glass: {
          type: 'Vidro',
          credit: null,
          valueUnit: null,
        },
        metal: {
          type: 'Metal',
          credit: null,
          valueUnit: null,
        },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.base-card-create-offer {
  margin-top: 32px;
  background: $card_nfe;
  border-radius: 10px;
  padding: 38px;
  text-align: center;

  .subtitle-card {
    color: $subtitle_text_dashboard;
    font-size: 1.5em;
  }

  .subtitle-base-card-create-offer {
    color: $subtitle_text_dashboard;
    font-size: 1.2em;
    text-align: start;
  }

  .title-card {
    color: $title_text_dashboard;
    font-size: 1.25em;
    font-weight: bold;
    text-align: left;
  }

  .content-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .generate-offer-button {
      margin-top: 32px;
      width: calc(300px - 32px);
      height: 50px;
    }
  }

  .credits-available-for-offer {

    .credits-container {
      margin-top:60px;
      width: 100%;
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;

      .btn-complete-registration {
        margin-top: 32px;
        width: 320px;
        height: 70px;
      }
    }
  }

  .content-card {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }

  .btn-complete-registration {
    margin-top: 32px;
    width: 220px;
    height: 56px;
  }
}
</style>
