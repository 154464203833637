<template>
  <div class="business-kind text-start">
    <h1 class="title-text">
      {{ $t('views.register.busness_kind.title') }}
    </h1>
    <h4 class="subtitle-text">
      {{ $t('views.register.busness_kind.sub_title') }}
    </h4>
    <div
      v-for="kind in kinds"
      :key="kind.value"
    >
      <business-card-kind
        :id="kind.value"
        :title="kind.title"
        :subtitle="kind.subtitle"
        :value="kind.value"
        :active="kind.value == selected"
        @click="selectKind"
        @confirm="nextStep"
      />
    </div>
  </div>
</template>
<script>
import BusinessCardKind from '@/components/cards/business/BusinessKindCard.vue';

export default {
  components: {
    BusinessCardKind,
  },
  data() {
    return {
      kinds: [
        {
          title: this.$t('views.register.busness_kind.cooperative.title'),
          subtitle: this.$t('views.register.busness_kind.cooperative.description'),
          value: 'cooperative_association',
        },
        {
          title: this.$t('views.register.busness_kind.waste_traders.title'),
          subtitle: this.$t('views.register.busness_kind.waste_traders.description'),
          value: 'waste_merchant',
        },
        // Cliente pediu para não mostrar a indústria
        // {
        //   title: this.$t('views.register.busness_kind.industry_or_company.title'),
        //   subtitle: this.$t('views.register.busness_kind.description'),
        //   value: 'industry_company',
        // },
        {
          title: this.$t('views.register.busness_kind.representative_business_industries.title'),
          subtitle: this.$t('views.register.busness_kind.representative_business_industries.description'),
          value: 'representative_business_industries',
        },
      ],
      selected: null,
    };
  },
  methods: {
    selectKind(selected) {
      this.selected = selected;
    },
    nextStep(value) {
      this.$emit('nextStep', { kind: value });
    },
  },
};
</script>
<style lang="scss" scoped>
  .business-kind {
    max-width: 940px;
    padding: 64px 32px 20px 32px;
    .title-text {
      color: $title_color_primary;
      font-size: 2em;
      font-weight: bold;
    }
    .subtitle-text {
      color: $title_color_primary;
      font-size: 1.2em;
      margin-bottom: 64px;
      margin-top: 16px;
      font-weight: 400;
      opacity: 0.75;
    }
  }
</style>
