<template>
  <div
    class="credit-card"
    @click="selectCard"
  >
    <div :class="styleCard">
      <div class="content-amount-credit">
        <div class="credits text-truncate">
          <v-progress-circular
            v-if="progressAvailableCredits"
            indeterminate
            :color="colorProgress"
          />
          <span v-else>
            {{ availableCredits }}
          </span>
        </div>
        <div>
          {{ mountTranslate('credit') }}
        </div>
        <div>
          {{ mountTranslate('prefix') }}
        </div>
      </div>

      <div>
        <a
          :class="[className, 'text-credit-pendings']"
          @click="proofOriginForPendingCredits()"
        >
          <strong>{{ hasPendingCredits }}</strong>
          {{ mountTranslate('credits_pendings') }}

        </a>
      </div>

      <div class="content-material">
        <div>{{ typesMaterial }}</div>
      </div>
    </div>

    <div class="card-infos-credit">
      <span class="label">
        {{ mountTranslate('suggest_value.title') }}
      </span>
      <span class="suggested-value">
        {{ convertSuggestedValueToBrl }}
        <v-icon
          size="20"
          color="primary"
        >
          mdi-arrow-top-right
        </v-icon>
      </span>
      <span class="label">
        {{ mountTranslate('offer_credit') }}
      </span>
      <div class="content-unit-value">
        <input
          v-model="credit"
          v-mask="['##',
                   '#,#',
                   '#,##',
                   '##,##',
                   '###,##',
                   '####,##',
                   '#.###,##',
                   '##.###,##',
                   '###.###,##']"
          class="input-offer-credit"
          placeholder="Ex: 500"
          :disabled="!active"
        >
        <div class="prefix">
          <span>{{ mountTranslate('prefix_quantity') }}</span>
        </div>
      </div>
      <span class="label">
        {{ mountTranslate('value_unit') }}
      </span>
      <div class="content-unit-value">
        <input
          v-model="value.valueUnit"
          v-mask="['###', '#,##', '##,##', '###,##']"
          class="input-offer-credit"
          placeholder="R$ 40,00"
          :disabled="!active"
        >
        <div class="prefix">
          <span>{{ mountTranslate('prefix_value') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import formatMask from '@/mixins/formatMask';

export default {
  name: 'CreditCard',
  mixins: [formatMask],
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    typesMaterial: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
    amountCredits: {
      type: Object,
      default: () => {},
    },
    creditsPendings: {
      type: Object,
      default: () => {},
    },
    suggestedValue: {
      type: [String, Number],
      default: 0,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      progressAvailableCredits: true,
      credit: null,
    };
  },

  computed: {
    styleCard() {
      return `content-credit ${this.className} ${this.active ? `card-${this.className}-selected` : ''}`;
    },

    inputDisabled() {
      return this.active ? 'disabled' : '';
    },

    hasPendingCredits() {
      return (this.creditsPendings.quantity || this.$t('components.card_available_for_offer.card_material.not_have')).toLocaleString('pt-BR');
    },

    availableCredits() {
      return (this.amountCredits.quantity || 0).toLocaleString('pt-BR');
    },

    colorProgress() {
      return `${this.className}_progress`;
    },
    convertSuggestedValueToBrl() {
      return this.suggestedValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    },
  },

  watch: {
    amountCredits(value) {
      this.progressAvailableCredits = !value;
    },

    credit(value) {
      this.value.credit = this.decimalMaskToNumber(value);
    },
  },

  methods: {
    ...mapActions([
      'sendCreditsForProofOrigin',
      'resetStateOfSuccess',
      'setStepsNfe',
      'resetNfesFiles',
      'addErrorMessage',
    ]),

    proofOriginForPendingCredits() {
      this.setStepsNfe(2);
      this.resetStateOfSuccess();
      this.resetNfesFiles();
      this.$router.push({ name: 'send-nfe' });
    },

    selectCard() {
      this.$emit('click', this.className);
    },

    mountTranslate(path, param) {
      return this.$t(`components.card_available_for_offer.card_material.${path}`, { value: param });
    },
  },
};
</script>

<style lang="scss" scoped>
  .credit-card {
    display: flex;
    height: 260px;
    margin-bottom: 64px;

    .content-credit {
      display: flex;
      cursor: pointer;
      flex-direction: column;
      justify-content: space-between;
      width: 180px;
      height: 100%;
      padding: 22px;
      border-radius: 10px;
      margin: 0 10px 10px 0;
      box-shadow: $shadow_card_credit 0px 5px 10px;
      &.paper {
        background: $background_card_paper;
        color: $color_card_paper;
      }

      &.plastic {
        background: $background_card_plastic;
        color: $color_card_plastic;
      }

      &.glass {
        background: $background_card_glass;
        color: $color_card_glass;
      }

      &.metal {
        background: $background_card_metal;
        color: $color_card_metal;
      }

      .content-amount-credit {
        font-size: 1em;
        font-weight: bold;
        .credits {
          width: 100%;
          font-size: 1.4em;
        }
      }

      .text-credit-pendings {
        font-size: 0.8em;
        transition: 200ms;

        &.paper {
          color: $color_card_paper;
        }

        &.plastic {
          color: $color_card_plastic;
        }

        &.glass {
          color: $color_card_glass;
        }

        &.metal {
          color: $color_card_metal;
        }

        &:hover {
          font-weight: bold;
          text-decoration: none;
        }
      }

      .content-material {
        font-size: 1em;
        font-weight: bold;
      }
    }

    .card-infos-credit {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 4px;
      margin-left: 8px;
      height: 100%;
      text-align: left;

      .label {
        font-weight: bold;
        color: $color_gray;
        margin: 6px 0px;
      }

      .suggested-value {
        color: $color_gray;
        font-size: 0.9em;
      }

      .content-unit-value {
        display: flex;

        .input-offer-credit {
          border: 1px solid $color_gray;
          border-radius: 6px 0px 0px 6px;
          padding: 4px;
          width: 80px;
          font-size: 0.86em;
          outline: none;
          padding-left: 8px;

          &:disabled {
            background: $disabled_background;
            border-color: $border_disabled_background;
          }
        }

        .prefix {
          height: 30px;
          width: 50px;
          text-align: center;
          background: $color_primary;
          color: $color_white;
          display: flex;
          align-items: center;
          padding: 0px 4px;
          border-radius: 0px 8px 8px 0px;
          justify-content: center;
          span {
            font-size: 0.8em;
            font-weight: bold;
          }
        }
      }
    }
  }
</style>
