<template>
  <v-dialog
    v-model="dialog"
    max-width="1024"
    :change="emitChange()"
  >
    <div class="business-kind-dialog">
      <business-kind @nextStep="nextStep" />
    </div>
  </v-dialog>
</template>
<script>
import { mapActions } from 'vuex';
import BusinessKind from '@/components/forms/register/business/BusinessKind.vue';

export default {
  components: { BusinessKind },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    value(value) {
      this.dialog = value;
    },
  },
  methods: {
    ...mapActions([
      'setKindBusiness',
    ]),
    nextStep(payload) {
      this.setKindBusiness(payload);
      this.$emit('submited');
    },
    emitChange() {
      this.$emit('change', this.dialog);
    },
  },
};
</script>
<style lang="scss" scoped>
.business-kind-dialog {
  padding: 0 32px;
  background-color: $app_background;
}
</style>
