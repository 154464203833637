var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-card-create-offer"},[_c('div',{staticClass:"title-card"},[_vm._v(" "+_vm._s(_vm.$t('components.card_available_for_offer.title_card'))+" ")]),(_vm.hasOperationalCapacity)?_c('div',[_c('div',{staticClass:"subtitle-base-card-create-offer"},[_vm._v(" "+_vm._s(_vm.$t('components.card_available_for_offer.subtitle_offer_credit'))+" ")]),_c('div',{staticClass:"credits-available-for-offer"},[_c('div',{staticClass:"credits-container"},[_c('credit-card',{attrs:{"class-name":"paper","types-material":_vm.$t('components.card_available_for_offer.materials.paper.name'),"amount-credits":_vm.getAvailablePaperCredit,"credits-pendings":_vm.getPaperCreditPending,"suggested-value":_vm.quickValue.paper,"active":_vm.isSelected(
            _vm.$t('components.card_available_for_offer.materials.paper.className')
          )},on:{"click":_vm.selectCard},model:{value:(_vm.offer.paper),callback:function ($$v) {_vm.$set(_vm.offer, "paper", $$v)},expression:"offer.paper"}}),_c('credit-card',{attrs:{"class-name":"plastic","types-material":_vm.$t('components.card_available_for_offer.materials.plastic.name'),"amount-credits":_vm.getAvailablePlasticCredit,"credits-pendings":_vm.getPlasticCreditPending,"suggested-value":_vm.quickValue.plastic,"active":_vm.isSelected(
            _vm.$t('components.card_available_for_offer.materials.plastic.className')
          )},on:{"click":_vm.selectCard},model:{value:(_vm.offer.plastic),callback:function ($$v) {_vm.$set(_vm.offer, "plastic", $$v)},expression:"offer.plastic"}}),_c('credit-card',{attrs:{"class-name":"glass","types-material":_vm.$t('components.card_available_for_offer.materials.glass.name'),"amount-credits":_vm.getAvailableGlassCredit,"credits-pendings":_vm.getGlassCreditPending,"suggested-value":_vm.quickValue.glass,"active":_vm.isSelected(
            _vm.$t('components.card_available_for_offer.materials.glass.className')
          )},on:{"click":_vm.selectCard},model:{value:(_vm.offer.glass),callback:function ($$v) {_vm.$set(_vm.offer, "glass", $$v)},expression:"offer.glass"}}),_c('credit-card',{attrs:{"class-name":"metal","types-material":_vm.$t('components.card_available_for_offer.materials.metal.name'),"amount-credits":_vm.getAvailableMetalCredit,"credits-pendings":_vm.getMetalCreditPending,"suggested-value":_vm.quickValue.metal,"active":_vm.isSelected(
            _vm.$t('components.card_available_for_offer.materials.metal.className')
          )},on:{"click":_vm.selectCard},model:{value:(_vm.offer.metal),callback:function ($$v) {_vm.$set(_vm.offer, "metal", $$v)},expression:"offer.metal"}})],1)]),_c('div',{staticClass:"content-button"},[_c('rectoplus-button-outlined',{staticClass:"generate-offer-button",attrs:{"disabled":!_vm.cardSelected,"text":_vm.$t('components.card_available_for_offer.buttons.credit_offer')},on:{"click":_vm.addResume}})],1)]):_c('div',[_c('div',{staticClass:"content-card"},[_c('label',{staticClass:"subtitle-card"},[_vm._v(" "+_vm._s(_vm.$t('components.card_available_for_offer.subtitle_register'))+" ")])]),_c('rectoplus-button',{staticClass:"btn-complete-registration",attrs:{"text":_vm.$t('components.card_available_for_offer.buttons.complete_registration')},on:{"click":_vm.checkBusinessKind}}),_c('business-kind-dialog',{on:{"submited":_vm.goToBusinessForm},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1),_c('generic-confirmation-dialog',{attrs:{"component":"out_of_range"},model:{value:(_vm.openDialog),callback:function ($$v) {_vm.openDialog=$$v},expression:"openDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }