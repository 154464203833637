<template>
  <v-col
    v-show="dialog"
    cols="auto"
  >
    <v-dialog
      v-model="dialog"
      :change="toggleDialog()"
      transition="dialog-top-transition"
      max-width="600"
    >
      <div class="card-dialog">
        <div class="title-text">
          {{ $t(`components.dialogs.${component}.title`) }}
        </div>
        <div class="subtitle-text">
          {{ $t(`components.dialogs.${component}.subtitle`) }}
        </div>
      </div>
      <button
        class="button-dialog"
        @click="closeDialog"
      >
        {{ $t(`components.dialogs.${component}.button`) }}
      </button>
    </v-dialog>
  </v-col>
</template>
<script>

export default {
  name: 'GenericConfirmationDialog',

  model: {
    prop: 'openDialog',
    event: 'change',
  },

  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },

    component: {
      type: String,
      default: '',
    },

    describe: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dialog: false,
    };
  },

  watch: {
    openDialog(value) {
      this.dialog = value;
    },
  },

  methods: {
    closeDialog() {
      this.dialog = false;
    },

    toggleDialog() {
      this.$emit('change', this.dialog);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-dialog {
  padding: 40px;
  border-radius: 10px;
  background: $color_light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .title-text {
    color: $color_dark;
    font-size: 1.875em;
    margin-bottom: 30px;
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
  .subtitle-text {
    width: 100%;
    text-align: center;
    color: $color_secondary;
    font-size: 1.5em;
  }

  .confirm-text {
    text-align: justify;
    color: $color_secondary;
    font-size: 1em;
  }
}
.button-dialog {
  margin-top: 40px;
  border-radius: 10px;
  color: $color_light;
  font-size: 1.5em;
  font-weight: bold;
  background: $color_primary;
  height: 70px;
}
</style>
