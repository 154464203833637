<template>
  <div
    :class="cardStyle"
    @click="selectCard"
  >
    <div class="kind-icon">
      <img :src="kindIcon">
    </div>
    <div class="text-container">
      <div class="card-title">
        {{ title }}
      </div>
      <div class="card-description">
        {{ subtitle }}
      </div>
    </div>
    <div
      class="ma-auto"
    >
      <button
        v-if="active"
      >
        <img
          class="btn-next"
          src="@/assets/icons/btn_next.svg"
        >
      </button>
    </div>
  </div>
</template>
<script>
import icCooperativa from '@/assets/icons/ic_cooperativa.svg';
import icComerciante from '@/assets/icons/ic_comerciante.svg';
import icIndustria from '@/assets/icons/ic_industria.svg';
import icRepresentativeBusinessIndustries from '@/assets/icons/ic_representative_business_industries.svg';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      style: false,
      card: { active: false },
      cooperative: icCooperativa,
      waste: icComerciante,
      industry: icIndustria,
      representativeBusinessIndustries: icRepresentativeBusinessIndustries,
    };
  },
  computed: {
    cardStyle() {
      return this.active ? 'business-kind-card card-selected' : 'business-kind-card';
    },
    kindIcon() {
      if (this.title === this.$t('views.register.busness_kind.cooperative.title')) return this.cooperative;
      if (this.title === this.$t('views.register.busness_kind.waste_traders.title')) return this.waste;
      if (this.title === this.$t('views.register.busness_kind.industry_or_company.title')) return this.industry;
      if (this.title === this.$t('views.register.busness_kind.representative_business_industries.title')) return this.representativeBusinessIndustries;
      return '';
    },
  },
  methods: {
    selectCard() {
      this.$emit(this.active ? 'confirm' : 'click', this.value);
    },
  },
};
</script>
<style lang="scss" scoped>
  .business-kind-card {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 100px 1fr 36px;
    grid-gap: 32px;
    cursor: pointer;
    background: $background_card_kind;
    margin: 40px 0px 30px;
    padding: 20px 40px 20px 20px;
    border-radius: 12px;
    box-sizing: border-box;
    align-items: center;
    &.card-selected {
      box-shadow: 0px 0px 6px 2px $color_primary;
      transition: 0.3s;
      border-radius: 6px;
    }
    .text-container {
      display: grid;
      .card-title {
        font-size: 1.2em;
        color: $color_text_card_kind;
        font-weight: bold;
      }
      .card-description {
        font-size: 0.875em;
        color: $color_text_card_kind;
        font-weight: 400;
      }
    }
    .btn-next {
      width: 44px;
      height: 44px;
    }
    .kind-icon {
      background: $color_primary;
      width: 100%;
      height: 100px;
      display: flex;
      flex-wrap: nowrap;
      padding: 10px;
      border-radius: 12px;
      justify-content: center;
    }
  }
  @media (max-width: $sm) {
    .business-kind-card {
      grid-auto-flow: row;
      grid-auto-columns: auto;
      padding: 20px;
    }
  }
</style>
